import Route from '@App/routes/Route';

/** Routes */

export const PLAYLISTS = new Route('playlists', '/playlist');
export const PLAYLIST = new Route('playlist', '/playlist/{slug}-{id}', { slug: '[^/]+',  id: '[0-9]+' }, PLAYLISTS);
export const PLAYLIST_LEGACY = new Route('playlist_legacy', '/playlist/{slug}', { slug: '[^/]+' }, PLAYLISTS);
export const LIBRARY = new Route('library', '/');
export const ALBUMS = new Route('albums', '/album', {});
export const ALBUM = new Route('album', '/album/{reference}', { reference: '[^/]+' }, ALBUMS);

export const LABELS = new Route('labels', '/label', {});
export const LABEL = new Route('label', '/label/{reference}-{slug}', { reference: '[^/-]+', slug: '[^/]+' }, LABELS);
export const PROJECTS = new Route('projects', '/mes-projets');
export const PROJECT = new Route('project', '/mes-projets/{id}', { id: '[^/]+' }, PROJECTS);

export const LICENCE_REQUESTS = new Route('licence_requests', '/mes-demandes-d-autorisation');
export const LICENCE_REQUEST_NEW = new Route('licence_request_new', '/mes-demandes-d-autorisation/nouvelle-demande', {}, LICENCE_REQUESTS);
export const LICENCE_REQUEST = new Route('licence_request', '/mes-demandes-d-autorisation/{id}', { id: '[^/]+' }, LICENCE_REQUESTS);

export const PROFILE = new Route('profile', '/mon-compte');
export const LEGAL_NOTICES = new Route('legal_notices', '/mentions-legales');
export const CART = new Route('cart', '/panier');
export const ORDERS = new Route('orders', '/mes-commandes');
export const ORDER = new Route('order', '/mes-commandes/{id}', { id: '[^/]+' }, ORDERS);

export const SUBSCRIPTION = new Route('subscription', '/abonnement');

export const HUBSPOT_PAGES = {
    HOME: '',
    OFFERS: 'offres',
    SERVICES: 'services',
    CGU: 'conditions-generales-utilisation',
    CGL: 'conditions-generales-licence',
    RATES: 'tarifs',
    CONTACT: 'nous-contacter',
    FAQ: 'support',
    DEVIS: 'offres/demande-devis',
};

/** Back-end routes (virtual) */

export const LOGIN = new Route('login', '/login');
export const LOGOUT = new Route('logout', '/logout');
export const REGISTER = new Route('register', '/creation-de-compte');
export const CREATE_PLAYLIST_FROM_PROJECT = new Route('admin_playlist_create', '/admin/v2/playlist/create?project={project}', { project: '[^/]+' });
export const DUPLICATE_PROJECT = new Route('admin_project_duplicate', '/admin/project/{project}/duplicate', { project: '[^/]+' });

/**
 * Routes as array
 *
 * @type {Array}
 */
const routes = [
    ALBUMS,
    ALBUM,
    CART,
    PLAYLISTS,
    PLAYLIST,
    PLAYLIST_LEGACY,
    LIBRARY,
    PROJECTS,
    PROJECT,
    LABELS,
    LABEL,
    LICENCE_REQUESTS,
    LICENCE_REQUEST_NEW,
    LICENCE_REQUEST,
    LEGAL_NOTICES,
    LOGIN,
    ORDER,
    ORDERS,
    PROFILE,
    REGISTER,
    SUBSCRIPTION,
];

/**
 * Find route by url
 *
 * @param  {String} url
 *
 * @return {Route}
 */
export function findByUrl(url) {
    return routes.find(route => route.match(url));
}

/**
 * Find route by name
 *
 * @param  {String} name
 *
 * @return {Route|null}
 */
export function findByName(name) {
    return routes.find(route => route.name === name) || null;
}

/** Export routes as array */
export default routes;

export function redirectToLogin() {
    window.location = LOGIN.getUrl();
}

export function redirectToSubscriptionForm() {
    window.location = SUBSCRIPTION.getUrl();
}

export function redirectToProfileSubscription() {
    window.location = PROFILE.getUrl() + '#abonnement';
}
